<template>
  <div id="member-txns" class="container-fluid">
    <div class="page-header mb-1">
      <strong>รายการเล่นเกมของสมาชิก</strong>
    </div>

    <DateFilter :with-market-id="false" :defaultMarketIds="[]" @submit="search" />

    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>

    <div class="card">
      <TxnsTable
        :account="account"
        :game="game"
        :items="items"
        :summary="summary"
        :totalItems="totalItems"
        :perPage="perPage"
        :currentPage="currentPage"
        @togglePage="togglePage"
        @toggleGame="toggleGame"
        @toggleAccount="toggleAccount"
      />
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />
  </div>
</template>
<script>
import DateFilter from '@/views/win-lose-report/components/DateFilter'
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import TxnsTable from './components/TxnsTable'
import moment from '@/helpers/moment'

export default {
  name: 'MemberTransactions',
  components: {
    DateFilter,
    TxnsTable
  },
  data() {
    return {
      data: null,
      isLoading: false,
      filters: {
        startDate: null,
        endDate: null
      },
      perPage: 100,
      currentPage: 1,
      game: null,
      account: null
    }
  },
  computed: {
    items() {
      return this.data?.items ?? []
    },
    totalItems() {
      return this.data?.summary?.count ?? 0
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    dateText() {
      return {
        start: moment(this.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      return moment(this.filters.endDate).diff(this.filters.startDate, 'd') + 1
    },
    summary() {
      return this.data?.summary ?? {
        betAmount: 0,
        comAmount: 0,
        creditAmount: 0,
        payoutAmount: 0,
        sumAmount: 0,
        count: 0
      }
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        startDate: filters.date.start,
        endDate: filters.date.end
      }

      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      const gameIds = this.game ? [this.game.gameId] : null
      GameService.getMemberHistory({
        ...this.filters,
        accountId: this.account?.accountId ?? null,
        gameIds: gameIds
      }, this.perPage, this.offset)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'ดึงรายการไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    togglePage(page) {
      this.currentPage = page
      this.loadItems()
    },
    toggleAccount(account) {
      this.account = account
      this.currentPage = 1
      this.loadItems()
    },
    toggleGame(game) {
      this.game = game
      this.currentPage = 1
      this.loadItems()
    }
  }
}
</script>
<style lang="scss">
#member-txns {
  .txns-table {
    .table {
      thead {
        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 1;
            font-size: 85%;
          }

          // th:first-child, td:first-child {
          //   //border-left: 0;
          // }

          // th:last-child, td:last-child {
          //   border-right: 0;
          // }
        }
      }
      tbody {
        background-color: #FFF;
        tr {
          td {
            padding: 5px;
            vertical-align: middle;
            font-size: 85%;
          }
          th {
            padding: 5px;
            font-size: 85%;
          }

          // th:first-child, td:first-child {
          //   border-left: 0;
          // }

          // th:last-child, td:last-child {
          //   border-right: 0;
          // }
        }
      }
      tfoot {
        tr {
          td {
            font-size: 85%;
          }

          th:first-child, td:first-child {
            border-left: 0;
          }

          th:last-child, td:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}
</style>
